.logo{
  width: 153px;
  margin-top: 15px;
  float: left;
}
.header{
  height: 60px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  background-color: #fff;
}
.header ul{
  float: left;
  overflow: hidden;
}
.header ul li{
  float: left;
  margin-left: 77px;
  line-height: 60px;
  font-size: 16px;
}
.header ul li a{
  color: #797E8C;
  display: block;
}
.header ul li:hover a{
  color: #202437;
}
.header ul li.active{
  position: relative;
}
.header ul li.active a{
  color: #202437;
}
.header ul li.active::after{
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 3px;
  background-color: #FF5F4B;
}
.phone-img{
  width: 18px;
}
.header-phone{
  float: right;
  line-height: 60px;
  font-size: 16px;
  color: #FF5F4B;
  font-weight: bold;
}
.phone-img{
  transform: translate(-4px, -1px);
}


@media screen and (max-width:1000px){
  .header ul li{
    margin-left: 46px;
  }
}
@media screen and (max-width:800px){
  .header{
    position: relative;
  }
  .header ul{
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
  }
  .header ul li{
    margin-left: 0;
    width: 25%;
    height:50px;
    line-height: 50px;
    text-align: center;
    background:rgba(171,38,2,0.3);
  }
  .header ul li a{
    color: #fff;
  }
  .header ul li.active a{
    color: #FFD092;
  }
  .header ul li.active::after{
    width: 64px;
    background-color: #FFD092;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width:640px){
  .header{
    height: 44px;
  }
  .logo{
    width: 100px;
    margin-top: 12px;
  }
  .header-phone{
    line-height: 44px;
  }
  .header ul li{
    font-size: 14px;
    height: 44px;
    line-height: 44px;
  }
}