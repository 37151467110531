html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, 
strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, 
tfoot, thead, tr, th, td ,textarea,input { margin:0; padding:0;  }
address,cite,dfn,em,var, i {font-style:normal;}
body {font-size: 16px; line-height: 1.5; font-family:'Microsoft Yahei';  color: #222; background: #fff; }
table { border-collapse:collapse; border-spacing:0; }
h1, h2, h3, h4, h5, h6, th { font-size: 100%; font-weight: normal; }
button,input,select,textarea{font-size:100%;}
fieldset,img{border:0;}
a { text-decoration: none; color: #666; background: none }
ul, ol { list-style: none; }
:focus{ outline:none;}
.clearfix{ clear: both; content: ""; display: block; overflow: hidden }
.clear{clear: both;}
.fl{ float: left; }
.fr{float: right;}
img{ width: 100%;}
.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.banner{
  margin-top: 59px;
  padding-top: 1px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width:1200px){
  .container{
    width: 96%;
    margin: 0 2%;
  }
}
@media screen and (max-width:800px){
  .banner{
    margin-top: 0;
  }
}
@media screen and (max-width:640px){
  .container{
    width: auto;
    margin: 0 16px;
  }
}