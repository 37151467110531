.banner{
  position: relative;
}
.mobile-banner{
  display: none;
}
.partner-category{
  height:70px;
  background:rgba(0,0,0,0.3);
  text-align: center;
  transform: translateY(-70px);
  position: relative;
  z-index: 99;
}
.partner-item{
  display: inline-block;
  line-height: 70px;
  font-size: 18px;
  color: #fff;
  padding: 0 74px;
  position: relative;
  cursor: pointer;
}
.partner-item::after{
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  background-color: #EBECF1;
  left: 0;
  top: 25px;
}
.partner-item:first-child::after{
  display: none;;
}
.partner-item.active{
 color: #FF5F4B; 
}
.partner-list-item{
  padding: 66px 0;
  border-bottom: 1px solid #F3F3F3;
  overflow: hidden;
}
.partner-list-item:last-child{
  border-bottom: 0;
}
.partner-img-list{
  float: left;
  overflow: hidden;
  width: 54.75%;

}
.partner-img-item{
  width: 31.81%;
  float: left;
  margin-right: 2.285%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.partner-img-item span{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background:rgba(255,95,75,0.3);
  transition: linear 0.5s;
}
.partner-img-item:hover span{
  opacity: 0;
}
.partner-img-item:last-child{
  margin-right: 0;
}
.partner-main{
  float: right;
  width: 39.75%;
}
.partner-logo-list{
  overflow: hidden;
}
.partner-logo{
  border-radius:5px;
  width:123px;
  float: left;
  margin-right: 20px;
}
.partner-main p{
  color: #202437;
  font-size: 15px;
  line-height: 30px;
  margin-top: 30px;
  text-indent:2em;
}

@media screen and (max-width:1200px){
  .partner-item{
    padding: 0 54px;
  }
}

@media screen and (max-width:1000px){
  .partner-item{
    padding: 0;
    width: 16.66%;
  }
}
@media screen and (max-width:800px){
  .partner-category{
    height: 56px;
    transform: translateY(0);
    background-color: #fff;
  }
  .partner-item{
    line-height: 56px;
    color: #202437;
  }
  .partner-item::after{
    top: 19px;
  }
}
@media screen and (max-width:800px){
  .mobile-banner{
    display: block;
  }
  .pc-banner{
    display: none;
  }
  .partner-item{
    font-size: 14px;
  }
  .partner-item::after{
    display: none;
  }
  .partner-list-item{
    padding: 30px 0;
  }
  .partner-img-list{
    width: 100%;
  }
  .partner-main{
    width: 100%;
    margin-top: 26px;
  }
  .partner-logo{
    width: 82px;
    height: auto;
    margin-right: 12px;
  }
  .partner-main p{
    margin-top: 20px;
    margin-bottom: 0;
  }
}