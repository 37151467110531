.about{
  text-align: center;
  font-size: 30px;
  color:#202437;
  margin-top: 100px;
  font-weight: bold;
}
.mobile-banner{
  display: none;
}
.about-desc{
  font-size: 15px;
  color: #515667;
  line-height: 30px;
  margin: 40px auto 0;
  width: 774px;
  text-indent:2em;
}
.about-list{
  display: flex;
  justify-content: space-between;
  margin: 60px 156px;
}
.about-item{
  text-align: center;
}
.about-item img{
  width: 80px;
}
.about-item p{
  font-size: 15px;
  color: #202437;
  margin-top: 15px;
}
.contact-list{
  margin: 60px auto 0;
}
.contact-item{
  float: left;
  width: 33.33%;
  text-align: center;
}
.contact-list h4{
  font-weight: bold;
  font-size: 20px;
  color: #202437;
}
.contact-list p{
  font-size: 20px;
  color: #202437;
  margin-top: 34px;
}
.contact-list button, .contact-list button a{
  width:120px;
  height:42px;
  background:rgba(255,95,75,1);
  border-radius:5px;
  color: #FFF;
  font-size: 16px;
  border: 0;
  margin-top: 16px;
  cursor: pointer;
}
.contact-list button.contact-phone{
  background-color: #fff;
  font-size: 24px;
  color: #FF5F4B;
  font-weight: bold;
  width: auto;
}


@media screen and (max-width:1000px){
  .about-list{
    margin: 60px 40px;
  }
  .about-desc{
    width: 100%;
  }
}
@media screen and (max-width:800px){
  .mobile-banner{
    display: block;
  }
  .pc-banner{
    display: none;
  }
  .about{
    margin-top: 30px;
    font-size: 20px;
  }
  .about-desc{
    margin-top: 20px;
  }
  .about-list{
    margin: 30px 0 50px;
    display: block;
  }
  .about-item{
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .about-item img{
    width: 60px;
    float: left;
    margin-left: 70px;
  }
  .about-item p{
    margin-left: 30px;
    float: left;
  }
  .contact-list{
    margin-top: 22px;
  }
  .contact-item{
    width: 100%;
    border-radius:5px;
    border:1px solid rgba(235,236,241,1);
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 14px;
  }
  .contact-list h4{
    text-align: left;
    margin: 14px 0 0 17px;
  }
  .contact-list p{
    text-align: left;
    margin: 0 0 0 17px;
    font-size: 14px;
  }
  .contact-list button{
    position: absolute;
    width: 94px;
    height: 30px;
    line-height: 30px;
    right: 15px;
    top: 26px;
    margin-top: 0;
    font-size: 14px;
  }
  .contact-list button a{
    font-size: 14px;
  }
  .contact-list button.contact-phone{
    font-size: 16px;
  }
}