
.dialog-wrap{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 199;
}
.dialog-shadow{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #999;
  opacity: 0.4;
}
.dialog-main{
  position: absolute;
  box-sizing: border-box;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow:0px 0px 10px 0px rgba(0,0,0,0.1);
  width:520px;
  height:390px;
  padding: 53px 90px 0;
}
.dialog-main h2{
  font-size: 20px;
  color: #202437;
  text-align: center;
  font-weight: bold;
}
.dialog-main p{
  text-align: center;
  color: #202437;
}
.dialog-input{
  border:1px solid rgba(235,236,241,1);
  border-radius: 5px;
  margin-top: 30px;
}
.dialog-item{
  height: 46px;
  border-bottom: 1px solid rgba(235,236,241,1);
  overflow: hidden;
}
.dialog-item input{
  height: 44px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  border: 0;
  border-radius: 5px;
  float: left;
}
input::-webkit-input-placeholder {
  color: #A1A3AF;
}
.border-none{
  border-bottom: none;
}
.verification-code{
  float: right;
  line-height: 46px;
  color: #FF5F4B;
  font-size: 14px;
  margin-right: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.dialog-errmsg{
  font-size: 14px;
  color: #FF5F4B;
  margin-top: 10px;
}
.dialog-btn{
  width: 120px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  color: #fff;
  background:rgba(255,95,75,1);
  border-radius:5px;
  margin: 38px auto 0;
  cursor: pointer;
}
.close{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

@media screen and (max-width:800px){
  .dialog-main{
    width: 90%;
    height: auto;
    padding: 53px 20px 40px;
  }
}