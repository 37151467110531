@import '~antd/dist/antd.css';
.carousel{
  margin-top: 59px;
  padding-top: 1px;
}
.mobile-carousel{
  display: none;
}
.benefit-list{
  margin-top: 70px;
}
.home-title{
  text-align: center;
  opacity: 0;
}
.home-title-t1{
  font-weight: bold;
  font-size: 30px;
  color: #202437;
}
.home-title-en{
  font-size: 20px;
  color: #515667;
  margin-top: 8px;
}
.benefit-catogary{
  overflow: hidden;
  float: left;
  width: 200px;
}
.benefit-catogary-item:last-child{
  display: none;
}
.benefit-catogary-item-text{
  height:50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #202437;
  cursor: pointer;
}
.benefit-catogary .active{
  background-color: #FF5F4B;
  border: 1px solid #FF5F4B;
  color: #fff;
}
.spu-list{
  margin-left: 246px;
  padding-bottom: 10px;
}
.spu-list .swiper-container{
  margin: 0;
  height: 450px;
}
.spu-list .swiper-container .spu-item{
  box-sizing: border-box;
  padding-right: 30px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2);
}
.spu-item-wrap{
  background-color: #F6F6F6;
  border-radius:5px;
  overflow: hidden;
  padding-bottom: 14px;
}
.spu-item-img{
  border-radius: 5px;
}
.spu-item-name{
  text-align: center;
  font-size: 20px;
  color: #202437;
  font-weight: bold;
  margin-top: 14px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.spu-item-desc{
  text-align: center;
  font-size: 15px;
  color: #797E8C;
}
.spu-list .ant-carousel .slick-dots{
  bottom: 0
}
.spu-list .ant-carousel .slick-dots li{
  width:10px;
  height:10px;
  background:rgba(225,226,230,1);
  border-radius: 50%;
  overflow: hidden;
}
.spu-list .ant-carousel .slick-dots li.slick-active{
  background:rgba(255,95,75,1);
}
.spu-list .ant-carousel .slick-dots li button{
  width:10px;
  height:10px;
}
.spu-list .ant-carousel .slick-dots li.slick-active button{
  background-color: #FF5F4B;
}
.spu-list .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: -6px;
}
.spu-list .swiper-pagination-bullet-active{
  background:rgba(255,95,75,1);
}
.spu-list .swiper-button-prev:after, .spu-list .swiper-button-next:after{
  font-size: 26px;
}
.service{
  margin-top: 126px;
}
.service-img{
  display: block;
  width: 100%;
  max-width: 908px;
  margin: 66px auto;
}
.advantages{
  margin-top: 200px;
}
.advantages-img{
  width: 100%;
  max-width: 1039px;
  margin-top: 170px;
}
.advantages-img2{
  width: 100%;
  max-width: 1039px;
  float: right;
  margin-top: 170px;
}
.advantages-img1{
  width: 100%;
  max-width: 1039px;
  float: right;
  margin-top: 100px;
}
.model{
  margin-top: 200px;
}
.model-img{
  width: 100%;
  max-width: 662px;
  display: block;
  margin: 117px auto 0;
}
.model-p{
  font-size: 20px;
  color: #202437;
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
}
.model-img2{
  margin-top: 40px;
}
.cooperation{
  margin-top: 200px;
}
.cooperation-p{
  margin-top: 44px;
  text-align: center;
  font-size: 20px;
  color: #202437;
}
.cooperation-img2{
  margin-top: 38px;
  margin-bottom: 50px;
}
.cooperation-img3{
  width: 100%;
  max-width: 996px;
  display: block;
  margin: 40px auto 0;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.fadeInUp {
  animation: fadeInUp linear 1s forwards;
}



.carousel-container{
  position: relative;
  z-index: 98;
}
.download-file{
  background-color: #fff;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 99;
  transform: translateY(-35px);
  box-shadow:0px 0px 10px 0px rgba(136,136,136,0.1);
}
.download-file::after{
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  background-color: #C6C8D0;
  left: 50%;
  top: 20px;
}
.download-li{
  float: left;
  width: 50%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: #202437;
  font-size: 18px;
  cursor: pointer;
}

.serve-list{
  overflow: hidden;
  margin-top: 60px;
}
.serve-list ul li{
  float: left;
  width: 48.33%;
  overflow: hidden;
  border:1px solid rgba(235,236,241,1);
  border-radius:5px;
  margin-bottom: 30px;
  transition: linear 0.4s;
}
.serve-list ul li:hover{
  border:1px solid #FF5F4B;
}
.serve-list ul li:nth-child(2n){
  float: right;
}
.serve-list ul li img{
  width: 100px;
  float: left;
  margin: 29px;
}
.serve-list ul li h2{
  margin-left: 160px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}
.serve-list ul li p{
  color: #515667;
  font-size: 15px;
  margin-top: 16px;
  margin-left: 160px;
  padding-right: 80px;
}
.value-wrap{
  background-color: #FBFBFB;
  margin-top: 130px;
  padding: 120px 0;
}
.value-list{
  margin-top: 60px;
}
.value-list ul{
  overflow: hidden;
}
.value-list ul li{
  box-sizing: border-box;
  float: left;
  width: 21.66%;
  height: 320px;
  background-color: #fff;
  border-radius:5px;
  border:1px solid rgba(235,236,241,1);
  margin-right: 4.416%;
  padding: 0 25px;
  transition: linear 0.4s;
}
.value-list ul li:hover{
  border:1px solid #FF5F4B;
}
.value-list ul li:last-child{
  margin-right: 0;
}
.value-list ul li img{
  margin-top: 34px;
  width: 50px;
}
.value-list ul li h2{
  font-size: 24px;
  color: #FF5F4B;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 50px;
  position: relative;
}
.value-list ul li h2::after{
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  background-color: #515667;
  left: 0;
  bottom: -28px;
}
.value-list ul li p{
  color: #515667;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 0;
}
.benefit-warp{
  margin-top: 150px;
  margin-bottom: 170px;
}
.benefit-main{
  margin-top: 88px;
  overflow: hidden;
}
.advantage{
  background:rgba(251,251,251,1);
  padding: 125px 0;
}
.advantage-list{
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.advantage-item{
  width: 23.33%;
  align-items: center;
}
.partner{
  margin-top: 155px;
}
.mobile-partner{
  display: none;
}
.partner-wrap{
  margin-top: 60px;
}

@media screen and (max-width:1281px){
  .spu-list .swiper-container{
    height: 410px;
  }
}

@media screen and (max-width:1000px){
  .serve-list ul li p{
    padding-right: 20px;
  }
  .value-list ul li{
    width: 23.938%;
    margin-right: 1.416%;
    padding: 0 14px;
  }
  .benefit-catogary{
    float: none;
    width: 100%;
    overflow: hidden;
  }
  .benefit-catogary-item{
    width: 100px;
    float: left;
    margin-right: 20px;
    margin-bottom: 14px;
  }
  .benefit-catogary-item-text{
    border: 1px solid #202437;
    font-size: 16px;
  }
  .benefit-catogary .active{
    border: 1px solid #FF5F4B;
  }
  .spu-list{
    margin-left: 0;
    margin-top: 20px;
  }
  .footer-r-item{
    width: 210px;
  }
  .benefit-list{
    margin-top: 40px;
  }
  .value-wrap{
    margin-top: 50px;
    padding: 70px 0;
  }
  .benefit-warp{
    margin-top: 70px;
  }
  .benefit-main{
    margin-top: 40px;
  }
  .advantage{
    margin-top: 70px;
    padding: 70px 0;
  }
  .partner{
    margin-top: 70px;
  }
}

@media screen and (max-width:800px){
  .carousel{
    margin-top: 0;
  }
  .value-list ul li{
    width: 48.6%;
    margin-bottom: 20px;
    margin-right: 2.216%;
  }
  .value-list ul li:nth-child(2n){
    margin-right: 0;
  }
  .spu-list .swiper-container .spu-item{
    padding-right: 16px;
  }
  .serve-list ul li p{
    padding-right: 15px;
  }
  .spu-item-name{
    font-size: 14px;
  }
}

@media screen and (max-width:640px){
  .download-li span{
    display: block;
  }
  .download-li{
    font-size: 14px;
    height: auto;
    line-height: 1.4;
    padding: 10px 0;
    text-decoration: underline;
  }
  .download-file::after{
    height: 20px;
  }
  .mobile-carousel{
    display: block;
  }
  .pc-carousel{
    display: none;
  }
  .benefit-list{
    margin-top: 0;
  }
  .home-title-t1{
    font-size: 18px;
  }
  .home-title-en{
    font-size: 14px;
  }
  .serve-list{
    margin-top: 30px;
  }
  .serve-list ul li{
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
    border-radius: 5px;
  }
  .serve-list ul li img{
    width: 70px;
    margin: 12px 10px;
  }
  .serve-list ul li h2{
    margin-left: 100px;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 0;
  }
  .serve-list ul li p{
    margin-top: 0;
    margin-left: 100px;
    font-size: 14px;
  }
  .value-wrap{
    margin-top: 30px;
    padding: 30px 0;
  }
  .value-list{
    margin-top: 30px;
  }
  .value-list ul li{
    height: 205px;
    padding: 0 10px;
  }
  .value-list ul li img{
    margin-top: 15px;
    width: 30px;
  }
  .value-list ul li h2{
    font-size: 15px;
    margin-top: 7px;
    margin-bottom: 20px;
  }
  .value-list ul li h2::after{
    bottom: -10px;
    width: 10px;
  }
  .value-list ul li p{
    font-size: 12px;
    line-height: 24px;
  }
  .benefit-warp{
    margin-top: 30px;
  }
  .benefit-main{
    margin-top: 25px;
  }
  .benefit-catogary-item{
    width: 78px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
  }
  .benefit-catogary-item-text{
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .spu-list .swiper-container{
    height: 214px;
  }
  .spu-list .swiper-container .spu-item{
    padding: 0 4px;
  }
  .spu-item-wrap{
    padding-bottom: 8px;
  }
  .spu-item-name{
    font-weight: normal;
    font-size: 12px;
    margin-top: 8px;
  }
  .spu-list{
    margin-top: 10px;
  }
  .advantage{
    margin-top: 30px;
    padding: 30px 0;
  }
  .advantage-list{
    margin-top: 25px;
    display: block;
  }
  .advantage-item{
    width: 49%;
    margin-right: 2%;
    float: left;
    margin-bottom: 10px;
  }
  .advantage-item:nth-child(2n){
    margin-right: 0;
  }
  .partner.container{
    margin: 30px 0 0;
  }
  .partner-wrap{
    margin-top: 10px;
  }
  .mobile-partner{
    display: block;
  }
  .pc-partner{
    display: none;
  }
}