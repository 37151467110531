.footer{
  background-color: #202437;
  padding: 56px 0 30px;
  margin-top: 226px;
}
.mobile-footer{
  display: none;
}
.copyright{
  text-align: center;
  font-size: 15px;
  color: #797E8B;
  margin-top: 24px;
}
.copyright a{
  color: #0091FF;
}
.code{
  float: right;
  width: 243px;
}
.footer-l{
  float: left;
}
.footer-logo{
  width: 123px;
}
.footer-l p{
  color: #fff;
  font-size: 14px;
  margin-top: 23px;
}
.footer-r{
  float: right;
  overflow: hidden;
}
.footer-r-item{
  float: left;
  width: 260px;
}
.footer-r-item:last-child{
  width: auto;
}
.footer-item-title{
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.footer-item-title a{
  color: #fff;
}
.footer-item-title a:hover{
  color: #fff;
}
.footer-r-item p{
  margin-top: 23px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.footer-r-item p a{
  color: #fff;
}
.advice{
  position: fixed;
  width: 80px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}
.advice-item{
  box-sizing: border-box;
  height: 80px;
  background-color: #FF5F4B;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  padding-top: 12px;
  cursor: pointer;
  position: relative;
}
.advice-item a{
  color: #fff;
}
.advice-item img{
  width: 30px;
}
.advice-item p{
  font-size: 12px;
  margin-top: 8px;
}
.advice-phone{
  box-sizing: border-box;
  position: absolute;
  width:158px;
  height:80px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 10px 0px rgba(0,0,0,0.1);
  text-align: center;
  font-size: 14px;
  color: #202437;
  right: 88px;
  top: 0;
  border-radius: 5px;
  padding-top: 12px;
  display: none;
}
.advice-phone h4{
  font-size: 18px;
  font-weight: bold;
  margin-top: 6px;
}
@media screen and (min-width:800px){
  .advice-event:hover .advice-phone{
    display: block;
  }
  .advice-event:hover a{
    color: #fff;
  }
}
@media screen and (max-width:1281px){
  .advice{
    right: 0;
  }
}
@media screen and (max-width:1000px){
  .footer{
    margin-top: 110px;
  }
}
@media screen and (max-width:800px){
  .advice{
    width: 56px;
    top: 84%;
  }
  .advice-item{
    height: 56px;
    padding-top: 7px;
  }
  .advice-item img{
    width: 23px;
  }
  .advice-item p{
    margin-top: 5px;
  }
  .footer{
    margin-top: 60px;
    padding-top: 30px;
  }
  .pc-footer{
    display: none;
  }
  .mobile-footer{
    display: block;
  }
  .mobile-footer-item{
    padding-bottom: 20px;
    margin-bottom: 25px;
    overflow: hidden;
    border-bottom: 1px solid #515667;
  }
  .mobile-footer-item:nth-child(2){
    border-bottom: 0;
    margin-bottom: 0;
  }
  .footer-r-item.mobile-footer-item-width{
    width: 72%;
  }
  .footer-item-title{
    margin-bottom: 14px;
    font-size: 16px;
  }
  .footer-r-item p{
    margin-top: 6px;
    margin-bottom: 0;
  }
  .mobile-footer-item-width .footer-logo{
    width: 90px;
    margin-bottom: 10px;
  }
  .copyright span{
    display: block;
  }
  .advice-event:hover a{
    color: #fff;
  }
}